import React, { useState } from "react";
import { Modal, message, Form, Input } from "antd";
import { $getRoot } from "lexical";
import * as mammoth from 'mammoth';
import { saveAs } from 'file-saver';
import { useSelector } from 'react-redux';
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import DropDown, { DropDownItem } from '../lexical/ui/DropDown';

const ImportExportMenu = ({
  Divider,
  editor,
  $insertNodes,

}) => {
  const [selectedExportType, setSelectedExportType] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fileName, setFileName] = useState("");
  const [form] = Form.useForm();
  const documentInformation = useSelector((state) => state.editor.documentInformation);

  const handleFileChange = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (!file) return;

    const allowedExtensions = ['.docx', '.html', '.txt', '.pad'];
    const fileExtension = `.${file.name.split('.').pop()}`.toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      message.error('Invalid file type!');
      event.target.value = '';
      return;
    }

    Modal.confirm({
      title: "Confirm",
      content: "Do you want to overwrite?",
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        try {
          switch (fileExtension) {
            case '.pad':
              await importLexicalFile(file);
              message.success("Pad file imported successfully!");
              break;
            case '.html':
              await importHtmlFile(file);
              message.success("HTML file imported successfully!");
              break;
            case '.txt':
              await importTextFile(file);
              message.success("Text file imported successfully!");
              break;
            case '.docx':
              await importDocxFile(file);
              message.success("DOCX file imported successfully!");
              break;
            default:
              console.error('Unsupported file type:', fileExtension);
              message.error('Unsupported file type!');
          }
        } catch (error) {
          console.error(`Error importing ${fileExtension} file:`, error);
          message.error(`An error occurred while importing the file. Please try again.`);
        } finally {
          event.target.value = '';
        }
      },
      onCancel: () => {
        event.target.value = '';
      },
    });
  };

  // Import .lexical file
  const importLexicalFile = async (file) => {
    const text = await file.text();
    const content = JSON.parse(text);
    editor.update(() => {
      editor.setEditorState(editor.parseEditorState(content));
    });
  };

  const importHtmlFile = async (file) => {
    try {
      const text = await file.text();
      editor.update(() => {
        const parser = new DOMParser();
        const dom = parser.parseFromString(text, 'text/html');
        const nodes = $generateNodesFromDOM(editor, dom);
        const root = $getRoot();
        root.clear();
        $insertNodes(nodes);
      });
    } catch (error) {
      console.error('Error importing HTML file:', error);
    }
  };

  // Import .txt file
  const importTextFile = async (file) => {
    const text = await file.text();
    editor.update(() => {
      const parser = new DOMParser();
      const dom = parser.parseFromString(text, 'text/html');
      const nodes = $generateNodesFromDOM(editor, dom);
      const root = $getRoot();
      root.clear();
      $insertNodes(nodes);
    });
  };

  // Import .docx file
  const importDocxFile = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    const result = await mammoth.convertToHtml({ arrayBuffer });
    const htmlContent = result.value;

    editor.update(() => {
      const parser = new DOMParser();
      const dom = parser.parseFromString(htmlContent, 'text/html');
      const nodes = $generateNodesFromDOM(editor, dom);
      $insertNodes(nodes);
    });
  };

  const handleMenuClick = (key) => {
    setSelectedExportType(key);
    const fileName = documentInformation?.name || "Untitled document";
    form.setFieldsValue({ fileName: fileName });
    setFileName(fileName);
    setIsModalVisible(true);

  };

  const handleExport = () => {
    if (!selectedExportType) return;

    editor.getEditorState().read(() => {
      const editorContent = editor.getEditorState().toJSON();

      switch (selectedExportType) {
        case 'pad':
          exportAsLexical(editorContent);
          break;
        case 'html':
          exportAsHTML();
          break;
        case 'txt':
          exportAsText();
          break;
        case 'pdf':
          exportAsPDF();
          break;
        default:
          console.error('Invalid export format selected.');
      }
    });
    setIsModalVisible(false);
  };

  const exportAsLexical = (content) => {
    const blob = new Blob([JSON.stringify(content, null, 2)], {
      type: 'application/json',
    });
    saveAs(blob, `${fileName}.pad`);
    message.success(`File successfully exported as ${fileName}.pad`);
  };

  const exportAsHTML = () => {
    const htmlString = generateHtmlFromEditorState();
    const blob = new Blob([htmlString], { type: 'text/html' });
    saveAs(blob, `${fileName}.html`);
    message.success(`File successfully exported as ${fileName}.html`);
  };

  const generateHtmlFromEditorState = () => {
    const container = document.createElement('div');
    const htmlNodes = $generateHtmlFromNodes(editor, null);
    container.innerHTML = htmlNodes;
    return container.innerHTML;
  };

  const exportAsText = () => {
    const stringifiedEditorState = JSON.stringify(
      editor.getEditorState().toJSON(),
    );
    const parsedEditorState = editor.parseEditorState(stringifiedEditorState);
    const textContent = parsedEditorState.read(() => $getRoot().getTextContent())
    const blob = new Blob([textContent], { type: 'text/plain' });
    saveAs(blob, `${fileName}.txt`);
    message.success(`File successfully exported as ${fileName}.txt`);
  };

  const exportAsPDF = async () => {
    try {
      const { jsPDF } = await import('jspdf');
      const doc = new jsPDF();

      editor.update(() => {
        const htmlContent = generateHtmlFromEditorState();
        doc.html(htmlContent, {
          callback: (doc) => {
            doc.save(`${fileName}.pdf`);
          },
          x: 10,
          y: 10,
          width: 190,
          windowWidth: 650
        });
      });
    } catch (error) {
      console.error('Error exporting PDF:', error);
    }
  };

  const handleFileNameChange = (e) => {
    setFileName(e.target.value)
  }

  return (
    <>
      <Divider />
      <button
        onClick={() => document.getElementById('fileInput').click()}
        className="toolbar-item spaced"
        type="button"
      >
        Import
      </button>
      <Divider />
      {/* Export Dropdown */}
      <DropDown buttonClassName='toolbar-item' buttonLabel="Export">
        <div className="export-dropdown">
          {['pad', 'html', 'txt', 'pdf'].map((format) => (
            <DropDownItem
              key={format}
              type="button"
              className="lexcial_editor_dropdown_item"
              onClick={() => handleMenuClick(format)}
            >
              .{format}
            </DropDownItem>
          ))}
        </div>

      </DropDown>
      <input
        id="fileInput"
        type="file"
        accept=".docx,.html,.txt,.pad"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />

      <Modal
        title="Set File Name"
        visible={isModalVisible}
        onOk={handleExport}
        onCancel={() => setIsModalVisible(false)}
      >
        <Form form={form}>
          <Form.Item
            label="File Name"
            name="fileName"
            rules={[
              {
                required: true,
                message: "Please enter a file name.",
              },
              {
                pattern: /^[^<>:;,?"*|/\\]+$/,
                message: "File name contains invalid characters.",
              },
            ]}
          >
            <Input
              value={fileName}
              onChange={handleFileNameChange}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ImportExportMenu;
